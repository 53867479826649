import React from "react"
import { Link } from "gatsby"
import footerStyles from "./footer.module.scss"

const Footer = () => {
  return (
    <footer>
      <div className={footerStyles.container}>
        <small>© {new Date().getFullYear()} Innenarchitektur Schmitter</small>
        <div className={footerStyles.legal}>
          <small>
            <Link to={"/impressum"}>Impressum</Link>
            <span className={footerStyles.spacer}>|</span>
            <Link to={"/datenschutz"}>Datenschutz</Link>
          </small>
        </div>
      </div>
    </footer>
  )
}

export default Footer
