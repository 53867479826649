import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import headerStyles from "./header.module.scss"
import scssVariables from "../../styles/variables.scss"

export class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showNavWindow: false,
      windowWidth: 0,
      ButtonState: "closed",
    }
  }

  componentDidMount() {
    this.setState({ windowWidth: window.innerWidth })
  }

  componentDidUpdate() {
    if (this.state.windowWidth !== window.innerWidth) {
      this.setState({ windowWidth: window.innerWidth })

      //Remove 'px' from variable
      const breakpointMobileLandscape = scssVariables.breakpointMobileLandscape.replace(
        /\D+/,
        ""
      )

      if (this.state.windowWidth > breakpointMobileLandscape) {
        this.setState({ showNavWindow: false })
      }
    }
  }

  navButtonHandler = () => {
    this.setState(prevState => {
      return { showNavWindow: !prevState.showNavWindow }
    })
    if (this.state.showNavWindow === true) {
      this.setState({ ButtonState: "close" })
      setTimeout(() => {
        this.setState({ ButtonState: "closed" })
      }, 400)
    } else if (this.state.showNavWindow === false) {
      this.setState({ ButtonState: "open" })
      setTimeout(() => {
        this.setState({ ButtonState: "opened" })
      }, 400)
    }
  }

  buttonStyle = target => {
    switch (this.state.ButtonState) {
      case "open":
        return `${headerStyles.navButtonLine} ${target} ${headerStyles.open}`

      case "opened":
        return `${headerStyles.navButtonLine} ${target} ${headerStyles.opened}`

      case "close":
        return `${headerStyles.navButtonLine} ${target} ${headerStyles.close}`

      case "closed":
        return `${headerStyles.navButtonLine} ${target}`

      default:
        break
    }
  }

  headerStyle = () => {
    if (this.state.showNavWindow === false) {
      if (typeof document !== `undefined`) {
        document.body.classList.remove("no-scroll")
        document.body.ontouchmove = e => {
          return true
        }
      }
      return headerStyles.header
    } else {
      if (typeof document !== `undefined`) {
        //Disallow scroll for body on desktop and mobile devices
        document.body.classList.add("no-scroll")
        document.body.ontouchmove = e => {
          e.preventDefault()
          return false
        }
      }
      return `${headerStyles.header} ${headerStyles.visible}`
    }
  }

  render() {
    return (
      <header className={this.headerStyle()}>
        <div className={headerStyles.container}>
          <div className={headerStyles.titleNavContainer}>
            <h1 className={headerStyles.siteTitle}>
              <Link to="/">{this.props.siteTitle}</Link>
            </h1>

            <nav>
              <ul className={headerStyles.navList}>
                <li>
                  <Link activeClassName={headerStyles.activeNavLink} to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    activeClassName={headerStyles.activeNavLink}
                    to="/projekte"
                  >
                    Projekte
                  </Link>
                </li>
                <li>
                  <Link
                    activeClassName={headerStyles.activeNavLink}
                    to="/buero"
                  >
                    Büro
                  </Link>
                </li>
                <li>
                  <Link
                    activeClassName={headerStyles.activeNavLink}
                    to="/kontakt"
                  >
                    Kontakt
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <button
            aria-label="Navigation Menu"
            className={headerStyles.navButton}
            onClick={this.navButtonHandler}
          >
            <div className={this.buttonStyle(headerStyles.a)}></div>
            <div className={this.buttonStyle(headerStyles.b)}></div>
          </button>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
